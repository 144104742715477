import { defineStore } from 'pinia';

interface SessionInterface {
  global: boolean,
  loading: boolean,
  loadingCounter: number,
}

export const useLoaderStore = defineStore({
  id: 'loader',
  state: (): SessionInterface => ({
    global: false,
    loading: false,
    loadingCounter: 0,
  }),
  getters: {
    isLoading: (state) => state.loadingCounter > 0,
    isLoadingGlobal: (state) => state.loading && state.global,
  },
  actions: {
    startLoading(global = false) {
      if(this.loadingCounter <= 0) {
        this.loadingCounter = 1;
      } else {
        this.loadingCounter += 1;
      }
      this.$patch({
        global,
        loading: true,
      });
    },
    stopLoading() {
      this.loadingCounter -= 1;
      if(this.loadingCounter <= 0) {
        this.$patch({
          loading: false,
          global: false,
        });
      }
    },
  },
});
