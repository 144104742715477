import { defineStore } from 'pinia';

const TIME_TO_REMOVE_MESSAGE = 6000;

interface ToasterInterface {
  messages: ToasterMessageInterface[]
}

interface ToasterMessageInterface {
  text: string;
}

export const useToasterStore = defineStore({
  id: 'toaster',
  state: (): ToasterInterface => ({
    messages: [],
  }),
  getters: {
    getMessages: (state) => state.messages.slice(-3),
  },
  actions: {
    addMessage(message: ToasterMessageInterface) {
      this.$patch((state) => {
        state.messages.push(message);
      });
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          this.removeMessage();
          resolve();
        }, TIME_TO_REMOVE_MESSAGE);
      });
    },
    removeMessage() {
      this.$patch((state) => {
        state.messages.shift();
      });
    },
  },
});
