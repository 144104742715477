import { createRouter, createWebHistory } from 'vue-router';

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean;
    needsToolbar?: boolean;
    needsSidebar?: boolean;
    requiresPermission?: ({ resource: string, action: string })[];
    requiresBeingRoot?: boolean;
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/cadastro',
      name: 'cadastro',
      component: () => import('@/views/auto_register/AutoRegisterView.vue'),
    },
    {
      path: '/formulario',
      name: 'formulario',
      component: () => import('@/views/auto_register/FormAutoRegisterView.vue'),
    },
    {
      path: '/login',
      alias: '/',
      name: 'login',
      component: () => import('@/views/LoginView.vue'),
    },
    {
      path: '/tela-inicial',
      name: 'tela-inicial',
      meta: {
        requiresAuth: true,
        needsToolbar: true,
        needsSidebar: true,
      },
      component: () => import('@/views/DashboardView.vue'),
    },
    {
      path: '/esqueci-minha-senha',
      name: 'esqueci-minha-senha',
      component: () => import('@/views/ForgotPasswordView.vue'),
    },
    {
      path:'/redefinir-senha',
      name: 'redefinir-senha',
      component: () => import('@/views/ResetPassword.vue'),
      props: route => ({ token: route.query.token, firstAccess: route.query.firstAccess }),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path:'/grupos',
      name: 'grupos',
      component: () => import('@/views/group/GroupView.vue'),
      meta: {
        needsSidebar: true,
        requiresAuth: true,
        needsToolbar: true,
      },
      children: [
        {
          path: '',
          redirect: { name: 'grupo-lista' },
        },
        {
          path:'lista',
          name:'grupo-lista',
          component: () => import('@/views/group/ListGroupView.vue'),
        },
        {
          path:'criar',
          name:'grupo-criar',
          component: () => import('@/views/group/CreateGroupView.vue'),
          meta: {
            requiresPermission: [{ resource: 'BenefitGroup', action: 'create' }],
          },
        },
        {
          path:'editar',
          name:'grupo-editar',
          component: () => import('@/views/group/EditGroupView.vue'),
          props: route => ({ groupId: parseInt(route.query.groupId as string) }),
          meta: {
            requiresPermission: [{ resource: 'BenefitGroup', action: 'update' }],
          },
        },
      ],
    },
    {
      path: '/colaboradores',
      name: 'colaboradores',
      component: () => import('@/views/collaborators/CollaboratorsView.vue'),
      meta: {
        requiresAuth: true,
        needsSidebar: true,
        needsToolbar: true,
      },
      children: [
        {
          path: '',
          redirect: { name: 'colaboradores-lista' },
        },
        {
          path: 'lista',
          name: 'colaboradores-lista',
          component: () => import('@/views/collaborators/ListCollaboratorsView.vue'),
        },
        {
          path: 'adicionar',
          name: 'colaboradores-adiconar',
          component: () => import('@/views/collaborators/AddCollaboratorsView.vue'),
          meta: {
            requiresPermission: [{ resource: 'Collaborator', action: 'create' }],
          },
        },
        {
          path: 'criar',
          name: 'colaboradores-criar',
          component: () => import('@/components/collaborators/create/CreateManually.vue'),
          meta: {
            requiresPermission: [{ resource: 'Collaborator', action: 'create' }],
          },
        },
        {
          path:'editar',
          name:'colaboradores-editar',
          component: () => import('@/views/collaborators/EditCollaboratorsView.vue'),
          props: route => ({ collaboratorId: parseInt(route.query.collaboratorId as string) }),
          meta: {
            requiresPermission: [{ resource: 'Collaborator', action: 'update' }],
          },
        },
        {
          path:'transferir-saldo',
          name:'colaboradores-transferir-saldo',
          component: () => import('@/views/collaborators/TransferBalanceView.vue'),
          props: route => ({ collaboratorId: parseInt(route.query.collaboratorId as string) }),
          meta: {
            requiresPermission: [{ resource: 'Collaborator', action: 'removing_balance' }],
          },
        },
      ],
    },
    {
      path: '/carga',
      name: 'carga',
      component: () => import('@/views/charge/ChargeView.vue'),
      meta: {
        needsSidebar: true,
        requiresAuth: true,
        needsToolbar: true,
      },
      children: [
        {
          path: '',
          name: 'carga-lista',
          component: () => import('@/views/charge/ChargeList.vue'),
        },
        {
          path: 'selecionar-beneficios',
          name: 'selecionar-beneficios',
          component: () => import('@/components/charge/charge_individual/SelectBenefits.vue'),
        },
        {
          path: 'selecionar-colaboradores',
          name: 'selecionar-colaboradores-carga',
          component: () => import('@/components/charge/charge_individual/SelectCollaborators.vue'),
        },
        {
          path: 'definir-carga',
          name: 'definir-carga',
          component: () => import('@/components/charge/charge_individual/DefineCharge.vue'),
        },
        {
          path: 'separar-pedido',
          name: 'separar-pedido-manual',
          component: () => import('@/components/charge/charge_individual/SplitCharge.vue'),
        },
        {
          path: 'resumo-carga-manual',
          name: 'resumo-carga-manual',
          component: () => import('@/components/charge/charge_individual/ManualChargeResume.vue'),
        },
        {
          path: 'pagamento-manual',
          name: 'pagamento-carga-manual',
          component: () => import('@/components/charge/charge_individual/Payment.vue'),
        },
        {
          path: 'grupo',
          name: 'carga-grupo',
          component: () => import('@/components/charge/charge_by_group/ChargeByGroups.vue'),
          meta: {
            requiresPermission: [
              { resource: 'Charge', action: 'create' },
              { resource: 'Charge', action: 'pay_with_account_balance' },
            ],
          },
        },
        {
          path: 'grupo-resumo',
          name: 'carga-grupo-resumo',
          component: () => import('@/components/charge/charge_by_group/ChargeByGroupOverview.vue'),
          meta: {
            requiresPermission: [
              { resource: 'Charge', action: 'create' },
              { resource: 'Charge', action: 'pay_with_account_balance' },
            ],
          },
        },
        {
          path: 'separar-pedido',
          name: 'carga-separar-pedido',
          component: () => import('@/components/charge/SplitCharge.vue'),
          meta: {
            requiresPermission: [
              { resource: 'Charge', action: 'create' },
              { resource: 'Charge', action: 'pay_with_account_balance' },
            ],
          },
        },
        {
          path: 'pagamento',
          name: 'pagamento',
          component: () => import('@/components/charge/PaymentView.vue'),
          meta: {
            requiresPermission: [
              { resource: 'Charge', action: 'create' },
              { resource: 'Charge', action: 'pay_with_account_balance' },
            ],
          },
        },
        {
          path: 'historico',
          name: 'carga-historio',
          component: () => import('@/components/charge/ChargeFullHistory.vue'),
        },
        {
          path: 'planilha',
          name: 'carga-planilha',
          component: () => import('@/components/charge/charge_by_spreadsheet/ChargeBySpreadsheetOverview.vue'),
          meta: {
            requiresPermission: [
              { resource: 'Charge', action: 'create' },
              { resource: 'Charge', action: 'pay_with_account_balance' },
            ],
          },
        },
        {
          path: 'planilha-pagamento',
          name: 'carga-planilha-pagamento',
          component: () => import('@/components/charge/charge_by_spreadsheet/SelectPaymentType.vue'),
          meta: {
            requiresPermission: [
              { resource: 'Charge', action: 'create' },
              { resource: 'Charge', action: 'pay_with_account_balance' },
            ],
          },
        },
        {
          path: 'planilha-separar-nota',
          name: 'carga-planilha-separar-nota',
          component: () => import('@/components/charge/charge_by_spreadsheet/SplitCharge.vue'),
          meta: {
            requiresPermission: [
              { resource: 'Charge', action: 'create' },
              { resource: 'Charge', action: 'pay_with_account_balance' },
            ],
          },
        },
      ],
    },
    {
      path: '/relatorios',
      name: 'relatorios',
      component: () => import('@/views/reports/ReportsView.vue'),
      meta: {
        needsSidebar: true,
        requiresAuth: true,
        needsToolbar: true,
      },
      children: [
        {
          path: '',
          name: 'relatorios-opcoes',
          component: () => import('@/components/reports/ReportsOptions.vue'),
        },
        {
          path: 'selecionar-unidade',
          name: 'solicitar-relatorio',
          component: () => import('@/components/reports/RequestReport.vue'),
          meta: {
            requiresPermission: [{ resource: 'BenefitBalanceReport', action: 'create' }],
          },
        },
        {
          path: 'relatorios-emitidos',
          name: 'mostrar-relatorios',
          component: () => import('@/components/reports/ShowReports.vue'),
          meta: {
            requiresPermission: [{ resource: 'BenefitBalanceReport', action: 'create' }],
          },
        },
        {
          path: 'colaboradores',
          name: 'relatorio-colaboradores',
          component: () => import('@/components/reports/collaborators_reports/CollaboratorsReportsOptions.vue'),
        },
        {
          path: 'extrato',
          name: 'extrato',
          component: () => import('@/components/reports/collaborators_reports/CollaboratorsStatementReport.vue'),
        }
      ],
    },
    {
      path: '/adicionar-saldo',
      name: 'adicionar-saldo',
      component: () => import('@/views/account_charge/AccountChargeView.vue'),
      redirect: { name: 'selecionar-empresa' },
      meta: {
        needsSidebar: true,
        requiresAuth: true,
        needsToolbar: true,
      },
      children: [
        {
          path: '',
          name: 'selecionar-empresa',
          component: () => import('@/views/account_charge/SelectBusinessUnitView.vue'),
        },
        {
          path: 'selecionar-pagamento',
          name: 'selecionar-pagamento-saldo-em-conta',
          component: () => import('@/views/account_charge/SelectAccountChargePaymentView.vue'),
        },
      ],
    },
    {
      path: '/solicitar-cartao',
      name: 'solicitar-cartao',
      component: () => import('@/views/request_card/RequestCardView.vue'),
      meta: {
        needsSidebar: true,
        requiresAuth: true,
        needsToolbar: true,
      },
      children: [
        {
          path: '',
          name: 'solicitar-cartao-opcoes',
          component: () => import('@/components/request_card/RequestCardOptions.vue'),
        },
        {
          path: 'selecionar-colaboradores',
          name: 'solicitar-cartao-colaboradores',
          component: () => import('@/components/request_card/CollaboratorsRequestCardList.vue'),
          meta: {
            requiresPermission: [{ resource: 'SecondWayRequest', action: 'request_second_way' }],
          },
        },
        {
          path: 'dados-entrega',
          name: 'solicitar-cartao-dados-entrega',
          component: () => import('@/components/request_card/DeleveryInformations.vue'),
          meta: {
            requiresPermission: [{ resource: 'SecondWayRequest', action: 'request_second_way' }],
          },
        },
        {
          path: 'forma-de-pagamento',
          name: 'solicitar-cartao-forma-de-pagamento',
          component: () => import('@/components/request_card/CardRequestPaymentType.vue'),
          meta: {
            requiresPermission: [{ resource: 'SecondWayRequest', action: 'request_second_way' }],
          },
        },
        {
          path: 'selecionar-endereco',
          name: 'selecionar-endereco',
          component: () => import('@/views/address/AddressView.vue'),
          meta: {
            requiresAuth: true,
            needsSidebar: true,
            needsToolbar: true,
          },
        },
        {
          path: 'novo-enderco',
          name: 'novo-endereco',
          component: () => import('@/views/address/AddressCreationView.vue'),
          meta: {
            requiresAuth: true,
            needsSidebar: true,
            needsToolbar: true,
          },
        },
        {
          path: 'editar-endereco/',
          name: 'editar-endereco',
          component: () => import('@/views/address/AddressUpdateView.vue'),
          meta: {
            requiresAuth: true,
            needsSidebar: true,
            needsToolbar: true,
          },
          props: route => ({ addressId: parseInt(route.query.addressId as string) }),
        },
      ],
    },
    {
      path: '/adiantamento-salarial',
      name: 'adiantamento-salarial',
      component: () => import('@/views/wage_advance/WageAdvanceView.vue'),
      meta: {
        needsSidebar: true,
        requiresAuth: true,
        needsToolbar: true,
      },
      children: [
        {
          path: '',
          name: 'adiantamento-salarial-lista',
          component: () => import('@/components/wage_advance/WageAdvanceList.vue'),
        },
        {
          path: '/segmentar-beneficio',
          name: 'segmentar-beneficio',
          component: () => import('@/components/wage_advance/SegmentBenefit.vue'),
        },
        {
          path: '/editar-limite-credito',
          name: 'editar-limite-credito',
          component: () => import('@/components/wage_advance/EditCreditLimit.vue'),
        },
        {
          path: '/adicionar-manualmente',
          name: 'adicionar-manualmente',
          component: () => import('@/components/wage_advance/edit_credit_limit/AddManually.vue'),
        },
        {
          path: '/emitir-relatorio-limite-credito',
          name: 'emitir-relatorio-limite-credito',
          component: () => import('@/components/wage_advance/edit_credit_limit/EmitReport.vue'),
        },
        {
          path: '/emitir-relatorio-saldo-antecipay-colaboradores',
          name: 'emitir-relatorio-saldo-antecipay-colaboradores',
          component: () => import('@/components/wage_advance/emit_collaborators_report/EmitCollaboratorsReport.vue'),
        },
        {
          path: '/emitir-relatorio-por-filial',
          name: 'emitir-relatorio-por-filial',
          component: () => import('@/components/wage_advance/edit_credit_limit/EmitReportByBusinessUnit.vue'),
        },
        {
          path: '/emitir-relatorio-saldo-antecipay-colaboradores-por-filial',
          name: 'emitir-relatorio-saldo-antecipay-colaboradores-por-filial',
          component: () => import('@/components/wage_advance/emit_collaborators_report/EmitCollaboratorsReportByBranch.vue'),
        },
      ],
    },
    {
      path: '/transferencia',
      name: 'transferencia',
      component: () => import('@/views/transfer/TransferView.vue'),
      meta: {
        needsSidebar: true,
        requiresAuth: true,
        needsToolbar: true,
      },
      children: [
        {
          path: '',
          name: 'transferencia-visao-geral',
          component: () => import('@/components/transfer/overview/TransferOverview.vue'),
        },
        {
          path:'/transferencia-entre-filias',
          name:'transferencia-entre-filiais',
          component: () => import('@/components/transfer/TransferBetweenBusinessUnit.vue'),
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/LoginView.vue'),
    },
    {
      path: '/trocar-senha/:resetToken',
      name: 'troca-de-senha',
      component: () => import('@/views/PasswordRecoverCollaborator.vue'),
      props: true,
    },
    {
      path: '/trocar-senha-app/:resetToken',
      name: 'trocar-de-senha-app',
      component: () => import('@/views/AppPasswordRecoverCollaborator.vue'),
      props: true,
    },
    {
      path: '/configuracoes',
      component: () => import('@/views/settings/SettingsView.vue'),
      meta: {
        needsSidebar: true,
        requiresAuth: true,
        needsToolbar: true,
      },
      children: [
        {
          path: '',
          name: 'settings',
          component: () => import('@/views/settings/SettingsOptions.vue'),
        },
        {
          path: 'perfil',
          name: 'settings-edit-account',
          component: () => import('@/views/settings/SettingsEditAccount.vue'),
        },
        {
          path: 'matriz',
          name: 'settings-edit-business',
          component: () => import('@/views/settings/SettingsEditBusiness.vue'),
          meta: {
            requiresBeingRoot: true,
          },
        },
        {
          path: 'filiais',
          component: () => import('@/views/settings/SettingsBusinessUnit.vue'),
          meta: {
            requiresBeingRoot: true,
          },
          children: [
            {
              path: '',
              name: 'settings-business-unit',
              component: () => import('@/views/business_unit/ListBusinessUnitView.vue'),
            },
            {
              path: 'adicionar',
              name: 'new-business-unit',
              component: () => import('@/views/business_unit/AddBusinessUnitView.vue'),
            },
            {
              path: 'editar/:id(\\d+)',
              name: 'edit-business-unit',
              component: () => import('@/views/business_unit/EditBusinessUnitView.vue'),
              props: true,
            },
          ],
        },
        {
          path: 'gestores',
          component: () => import('@/views/settings/SettingsManager.vue'),
          meta: {
            requiresBeingRoot: true,
          },
          children: [
            {
              path: '',
              name: 'settings-managers',
              component: () => import('@/views/managers/ListManagerView.vue'),
            },
            {
              path: 'adicionar',
              name: 'new-manager',
              component: () => import('@/views/managers/AddManagerView.vue'),
            },
            {
              path: 'editar/:id(\\d+)',
              name: 'edit-manager',
              component: () => import('@/views/managers/EditManagerView.vue'),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: '/swap-virtual-card',
      component: () => import('@/views/SwapVirtualCard.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/vendedores/cadastrar-empresa',
      component: () => import('@/views/RegisterBySeller.vue'),
      meta: {
        requiresAuth: false,
      },
    },
  ],
});

export default router;
