<template>
  <div class="flex justify-center">
    <TransitionGroup v-if="!hasMessages" tag="div" class="fixed bottom-8 w-64 mx-auto z-50 p-4" name="fade">
      <div
        v-for="(message, index) in messages"
        class="border-solid mt-4 text-sm p-4 rounded bg-gray-500 w-64"
        :key="index"
      >
        <p class="text-white text-center">{{ message.text }}</p>
      </div>
    </TransitionGroup>
  </div>
</template>
<script setup lang="ts">
  import { useToasterStore } from '@/stores/toaster';
  import { storeToRefs } from 'pinia';
  import { computed } from 'vue';

  const { getMessages: messages } = storeToRefs(useToasterStore());

  const hasMessages = computed(() => messages.value.length === 0)
</script>
<style scoped>
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(0, 30px);
}
.fade-leave-active {
  position: absolute;
}
</style>
