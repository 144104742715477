<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12.955" height="16" viewBox="0 0 12.955 16">
    <path id="ic_colaboradores" d="M11.42-2205.8a.735.735,0,0,1-.734-.735c0-3.131-2.247-5.677-5.009-5.677s-5.009,2.547-5.009,5.677a.735.735,0,0,1-.734.735.735.735,0,0,1-.735-.735c0-3.94,2.906-7.146,6.477-7.146s6.477,3.206,6.477,7.146A.735.735,0,0,1,11.42-2205.8Zm-5.743-8.787a3.615,3.615,0,0,1-3.606-3.606,3.61,3.61,0,0,1,3.606-3.607,3.61,3.61,0,0,1,3.606,3.607A3.61,3.61,0,0,1,5.677-2214.586Zm0-5.745a2.144,2.144,0,0,0-2.137,2.137,2.139,2.139,0,0,0,2.137,2.136,2.139,2.139,0,0,0,2.136-2.136A2.139,2.139,0,0,0,5.677-2220.331Z" transform="translate(0.8 2221.8)" :fill="fillColor()"/>
  </svg>
</template>
<script setup lang="ts">
  const props = defineProps<{
    focus?: boolean;
    color?: string;
  }>();

  const fillColor = () => props.color ? props.color : (props.focus ? '#FFFFFF' : '#7A7786');
</script>

