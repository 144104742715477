import { createApp } from 'vue';
import { createPinia } from 'pinia';
// import * as Sentry from '@sentry/vue';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import router from './router';
import { i18n } from './plugins/i18n';
import { firebase } from './plugins/firebase';
import { maska } from 'maska';
import 'virtual:fonts.css';
import { getAnalytics } from 'firebase/analytics';
import { recaptcha } from './plugins/recaptcha';
import { blipChat } from './plugins/blipChat';
import VueApexCharts from 'vue3-apexcharts';
import ApexCharts from 'apexcharts';

const app = createApp(App);
const pinia = createPinia();
getAnalytics(firebase);

pinia.use(piniaPluginPersistedstate);

// TODO: Find better configuration
// if (!import.meta.env.DEV) {
//   Sentry.init({
//     app,
//     dsn: import.meta.env.VITE_SENTRY_URL,
//     integrations: [
//       Sentry.browserTracingIntegration({ router }),
//       Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', /^https:\/\/grh\.ecxpay\.com\.br/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(recaptcha);
app.use(VueApexCharts);
app.use(blipChat, { router });
app.directive('maska', maska);

app.mount('#app');

app.config.globalProperties.$apexcharts = ApexCharts;

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $apexcharts: typeof ApexCharts;
  }
}

