<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path id="ic_dashboard" d="M-17.509,998A2.5,2.5,0,0,1-20,995.527v-8.092a2.467,2.467,0,0,1,1.308-2.16L-13.2,982.3a2.519,2.519,0,0,1,1.191-.3,2.518,2.518,0,0,1,1.191.3l5.509,2.959A2.469,2.469,0,0,1-4,987.419v8.109A2.494,2.494,0,0,1-6.5,998Zm5.5-14.8a1.3,1.3,0,0,0-.609.153l-5.509,2.964a1.269,1.269,0,0,0-.661,1.117v8.093A1.278,1.278,0,0,0-17.5,996.8h11a1.278,1.278,0,0,0,1.277-1.27v-8.092a1.267,1.267,0,0,0-.671-1.118l-5.5-2.958a1.292,1.292,0,0,0-.612-.158l0-.078Zm-2.372,12.549a.6.6,0,0,1-.607-.6.6.6,0,0,1,.607-.6h4.748a.6.6,0,0,1,.607.6.6.6,0,0,1-.607.6Z" transform="translate(20 -982)" :fill="fillColor()"/>
  </svg>
</template>
<script setup lang="ts">
  const props = defineProps<{
    focus?: boolean;
  }>();

  const fillColor = () => props.focus ? '#FFFFFF' : '#7A7786';
</script>