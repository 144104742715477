import router from './appRoutes';
import { useSessionStore } from '@/stores/session';
import { verifyPermission } from '@/composables/verifyPermission';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useBannerStore } from '@/stores/banners';

const nextWithBanner = (next: CallableFunction, verifyBanner: CallableFunction, to: string) => {
  next();
  verifyBanner(to);
};

router.beforeEach((to, _from, next) => {
  const store = useSessionStore();
  const userStore = useUserStore();
  const { currentUser } = storeToRefs(userStore);
  const { hasSession } = storeToRefs(useSessionStore());

  if (hasSession.value && !currentUser.value) {
    userStore.refreshUser({ refresh_token: true });
  }

  if (hasSession.value && currentUser.value) {
    userStore.refreshUser();
  }

  if (to.meta.requiresAuth && !store.hasSession) {
    next({ name: 'login' });
  } else {
    next();
  }
});

router.beforeResolve(async (to, _, next) => {
  const store = useSessionStore();
  const userStore = useUserStore();
  const { verifyBannerRoute } = useBannerStore();

  if (to.meta.requiresBeingRoot) {
    return userStore.isRoot ? nextWithBanner(next, verifyBannerRoute, to.fullPath) : next({ name: 'tela-inicial' });
  }

  if (store.hasSession && !userStore.isRoot) {
    await userStore.fetchPermissions();
  }

  if (to.meta.requiresPermission) {
    const hasPermission = to.meta.requiresPermission.some((permission) => {
      return verifyPermission(permission.resource, permission.action);
    });

    return hasPermission ? nextWithBanner(next, verifyBannerRoute, to.fullPath) : next({ name: 'tela-inicial' });
  }

  return nextWithBanner(next, verifyBannerRoute, to.fullPath);
});

export default router;
