import { defineStore } from 'pinia';

interface ErrorInterface {
  line: number,
  message: object | string,
}

export const useSpreadsheetErrorsStore = defineStore({
  id: 'errors',
  state: (): { errors: ErrorInterface[], type: string } => ({
    errors: [],
    type: '',
  }),
  getters: {
    getErrors: (state) => state.errors,
    hasErrors: (state) => state.errors.length > 0,
    getType: (state) => state.type,
  },
  actions: {
    setErrors(errors: ErrorInterface[], type = 'collaborator') {
      this.errors = errors;
      this.type = type;
    },
    clearErrors() {
      this.errors = [];
    },
  },
});
