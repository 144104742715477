<template>
  <Teleport to="body">
    <transition name="modal">
      <div v-if="openWithRoute" class="fixed top-0 left-0 w-full h-screen z-30 bg-black bg-opacity-10 flex items-center justify-center transition-all">
        <transition name="image">
          <div ref="imageRef" class="max-w-5xl 2xl:max-w-7xl aspect-auto rounded-md relative overflow-hidden transition-all" v-if="openWithRoute">
            <a @click="openInfo" class="cursor-pointer"><img class="w-full" :src="currentBanner?.image" alt=""></a>
            <div @click.stop="closeAction" class="absolute cursor-pointer bg-white px-2 rounded-full top-4 right-4">x</div>
            <div class="absolute bottom-3 right-4 z-40">
              <EcxCheckBoxSimple
                :id="currentBanner?.id || 0"
                :name="t('components.global_banner.dont_see_more')"
                description=""
                :input-props="{ id: currentBanner?.id.toString() || '', name: 'bannerCheckbox' }"
                :field-checked="false"
              />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </Teleport>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia';
  import { useForm } from 'vee-validate';
  import { useI18n } from '@/composables/useI18n';
  import { useBannerStore } from '@/stores/banners';
  import { ref, watch, onMounted, computed } from 'vue';
  import useDetectOutsideClick from '@/composables/clickOutside';
  import EcxCheckBoxSimple from '@/components/form/EcxCheckBoxSimple.vue';

  const openImage = ref(false);
  const imageRef = ref();
  const { values } = useForm();
  const { t } = useI18n();

  const store = useBannerStore();
  const { fetchPopupBanner, closeModal, setAlreadySeen } = store;
  const { isModalOpen, currentBanner, alreadySeenToday } = storeToRefs(store);

  onMounted(() => {
    fetchPopupBanner();
  });

  const openInfo = () => {
    window.open(currentBanner.value?.redirect_link);
    closeModal();
  };

  const openWithRoute = computed(() => isModalOpen.value && !(alreadySeenToday.value));

  const closeAction = () => {
    openImage.value = false;
    closeModal();
  };

  watch(openWithRoute, () => { if(openWithRoute.value) setTimeout(() => {
    openImage.value = true;
  }, 400); });

  useDetectOutsideClick(imageRef, () => { 
    openImage.value = false;
  });

  watch(
    () => values.bannerCheckbox,
    () => {
      if(openImage.value) {
        setAlreadySeen(!!values.bannerCheckbox);
      }
    },
  );
</script>

<style scoped>
  .modal-enter-active,
  .modal-leave-active {
    opacity: 0;;
    transition: all .25s ease;
  }

  .modal-enter-from,
  .modal-leave-to{
    transform: scale(1.1);
  }

  .image-enter-active,
  .image-leave-active {
    transform: translateY(0px);
    transition: all .25s ease;
  }

  .image-enter-from,
  .image-leave-to{
    opacity: 0;
    transform: translateY(100px);
  }
</style>