<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.733" height="13" viewBox="0 0 17.733 13">
    <path id="ic_grupos" d="M42.543-2691.591a2.346,2.346,0,0,0-2.167-2.488,1.933,1.933,0,0,0-.932.248,5.953,5.953,0,0,1,.42,2.2.591.591,0,0,1-.591.591.591.591,0,0,1-.591-.591,4.106,4.106,0,0,0-3.821-4.334,4.106,4.106,0,0,0-3.822,4.334.591.591,0,0,1-.591.591.591.591,0,0,1-.591-.591,5.953,5.953,0,0,1,.42-2.2,1.931,1.931,0,0,0-.932-.248,2.347,2.347,0,0,0-2.167,2.488.59.59,0,0,1-.591.591.59.59,0,0,1-.591-.591,3.524,3.524,0,0,1,3.349-3.669,3.113,3.113,0,0,1,1.481.386,4.868,4.868,0,0,1,4.034-2.267,4.867,4.867,0,0,1,4.034,2.267,3.115,3.115,0,0,1,1.481-.386,3.523,3.523,0,0,1,3.348,3.669.59.59,0,0,1-.591.591A.59.59,0,0,1,42.543-2691.591Zm-4.491-7.1a2.245,2.245,0,0,1,2.245-2.245,2.245,2.245,0,0,1,2.245,2.245,2.245,2.245,0,0,1-2.245,2.245A2.245,2.245,0,0,1,38.052-2698.691Zm1.182,0a1.064,1.064,0,0,0,1.064,1.064,1.064,1.064,0,0,0,1.064-1.064,1.064,1.064,0,0,0-1.064-1.064A1.063,1.063,0,0,0,39.234-2698.691Zm-12.212,0a2.246,2.246,0,0,1,2.246-2.245,2.245,2.245,0,0,1,2.245,2.245,2.245,2.245,0,0,1-2.245,2.245A2.245,2.245,0,0,1,27.022-2698.691Zm1.182,0a1.064,1.064,0,0,0,1.064,1.064,1.064,1.064,0,0,0,1.064-1.064,1.064,1.064,0,0,0-1.064-1.064A1.063,1.063,0,0,0,28.2-2698.691Zm3.659-2.39A2.919,2.919,0,0,1,34.782-2704a2.919,2.919,0,0,1,2.92,2.919,2.92,2.92,0,0,1-2.92,2.92A2.922,2.922,0,0,1,31.863-2701.081Zm1.182,0a1.738,1.738,0,0,0,1.738,1.737,1.738,1.738,0,0,0,1.738-1.737,1.738,1.738,0,0,0-1.738-1.738A1.74,1.74,0,0,0,33.045-2701.08Z" transform="translate(-25.995 2704.001)" :fill="fillColor()"/>
  </svg>
</template>
<script setup lang="ts">
  const props = defineProps<{
    focus?: boolean;
  }>();

  const fillColor = () => props.focus ? '#FFFFFF' : 'currentColor';
</script>
