import { useScriptTag } from '@vueuse/core';

const RECAPTCHA_URI = import.meta.env.VITE_RECAPTCHA_URI;

export const recaptcha = {
  install() {
    useScriptTag(
      RECAPTCHA_URI,
      () => {
        // do something on script tag loaded.
      },
      { async: false },
    );
  },
};
