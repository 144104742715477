import { apiService } from '../api.service';

import type { IPermissionType } from '@/interfaces/permissionTypes';
import type { LoginParamsInterface, UserResponseInterface, ShowParamsInterface, EditAccountParamsInterface,
              RecoverPasswordParamsInterface, ResetPasswordParamsInterface } from '@/interfaces/users';

const UsersResource = {
  login: (params: LoginParamsInterface) => apiService.post<UserResponseInterface>('/v1/hr_manager/sign_in', params),
  show: (params?: ShowParamsInterface) => apiService.get<UserResponseInterface>('v1/hr_managers/me', params),
  fetchPermissions: () => apiService.get<IPermissionType[]>('v1/hr_managers/me/permissions'),
  update: (params: EditAccountParamsInterface) => apiService.put<UserResponseInterface>('v1/hr_managers/me', params),
  recoverPassword: (params: RecoverPasswordParamsInterface) => apiService.post('v1/hr_managers/recover_password', params),
  resetPassWord: (params: ResetPasswordParamsInterface) => apiService.post('v1/hr_managers/reset_password', params),
};

export default UsersResource;
