import type { LoginParamsInterface, EditAccountParamsInterface, UserInterface, ShowParamsInterface } from '@/interfaces/users';
import type { IPermissionType } from '@/interfaces/permissionTypes';
import type { IBusinessUnit } from '@/interfaces/business_unit';

import UsersResource from '@/api/resources/users';

import { i18nGlobalInstance } from '@/plugins/i18n';
import { defineStore } from 'pinia';
import { useLoaderStore } from './loader';
import { useSessionStore } from './session';
import { useToasterStore } from '@/stores/toaster';

interface UserStateInterface {
  user?: UserInterface;
  permissions?: IPermissionType[];
  businessUnits?: IBusinessUnit[];
  useOnlyVirtualCards?: boolean;
}

export const useUserStore = defineStore({
  id: 'user',
  persist: true,
  state: (): UserStateInterface => ({
    user: undefined,
    permissions: [],
    businessUnits: [],
    useOnlyVirtualCards: false,
  }),
  getters: {
    currentUser: (state) => state.user,
    getPermissions: (state) => state.permissions,
    getBusinessUnits: (state) => state.businessUnits,
    hasBusinessUnits: (state) => {
      if (state.businessUnits) {
        return state.businessUnits.length > 0;
      }

      return false;
    },
    isRoot: (state) => !!state.user?.is_root,
  },
  actions: {
    login(params: LoginParamsInterface) {
      const session = useSessionStore();
      const loaderStore = useLoaderStore();
      loaderStore.startLoading(true);
      UsersResource.login(params).then((user) => {
        this.$patch({
          user: {
            email: user.email,
            name: user.name,
            is_root: user.is_root,
          },
          permissions: user.permissions,
          businessUnits: user.business_units,
          useOnlyVirtualCards: user.using_only_virtual_card ?? false,
        });
        session.createSession({
          email: user.email,
          token: user.token,
        });
      });
    },
    refreshUser(params?: ShowParamsInterface) {
      const session = useSessionStore();
      const loaderStore = useLoaderStore();
      loaderStore.startLoading(true);
      UsersResource.show(params).then((user) => {
        this.$patch({
          user: {
            email: user.email,
            name: user.name,
            is_root: user.is_root,
          },
          useOnlyVirtualCards: user.using_only_virtual_card ?? false,
          permissions: user.permissions,
          businessUnits: user.business_units,
        });
        if (user.token && user.email) {
          session.createSession({
            email: user.email,
            token: user.token,
          });
        }
      });
    },
    fetchPermissions() {
      const loaderStore = useLoaderStore();
      loaderStore.startLoading(true);
      UsersResource.fetchPermissions().then((permissions) => {
        this.$patch({ permissions });
      });
    },
    update(params: EditAccountParamsInterface, callback: () => void) {
      const session = useSessionStore();
      const loaderStore = useLoaderStore();
      const { addMessage } = useToasterStore();
      loaderStore.startLoading(true);
      UsersResource.update(params).then((user) => {
        addMessage({ text: i18nGlobalInstance.t('success.operation') });
        this.$patch({
          user: {
            email: user.email,
            name: user.name,
          },
          permissions: user.permissions,
          businessUnits: user.business_units,
        });
        if (user.email || user.token) {
          session.createSession({
            email: user.email,
            token: session.token,
          });
        }
        callback();
      });
    },
    logout() {
      this.$reset();
      const session = useSessionStore();
      session.destroySession();
    },
  },
});
