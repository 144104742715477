import { useUserStore } from '@/stores/user';

// TODO: Mapear as permissões existentes da aplicação dentro de um ENUM
// para passar apenas um parâmetro no verifyPermission
export const verifyPermission = (resource: string, action: string): boolean => {
  const userStore = useUserStore();

  if (userStore.isRoot) return true;

  const permission = userStore.permissions?.find((permission) => {
    const hasResource = ['*', resource].includes(permission.subject_class);
    const hasAction = ['*', action].includes(permission.action);

    return hasResource && hasAction;
  });

  return Boolean(permission);
};
