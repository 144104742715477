<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.58" height="17.666" viewBox="0 0 17.58 17.666">
    <path id="refund" d="M17.28,8.635a8.625,8.625,0,1,1-17.25,0,.576.576,0,0,1,1.152,0,7.48,7.48,0,1,0,.777-3.327h1.2a.576.576,0,0,1,0,1.152H.576A.576.576,0,0,1,0,5.884V3.133a.576.576,0,1,1,1.152,0V4.375A8.622,8.622,0,0,1,17.28,8.635Zm-9.216,2.81V9.165a2.506,2.506,0,0,1-2.3-2.258,2.506,2.506,0,0,1,2.3-2.258V4.027a.576.576,0,1,1,1.152,0V4.65a2.506,2.506,0,0,1,2.3,2.258.576.576,0,0,1-1.152,0c0-.49-.489-.92-1.152-1.082V8.106a2.506,2.506,0,0,1,2.3,2.258,2.506,2.506,0,0,1-2.3,2.258v.622a.576.576,0,1,1-1.152,0v-.622a2.506,2.506,0,0,1-2.3-2.258.576.576,0,1,1,1.152,0C6.912,10.854,7.4,11.283,8.064,11.445ZM9.216,9.281v2.164c.663-.162,1.152-.592,1.152-1.082s-.489-.92-1.152-1.082ZM8.064,7.989V5.825c-.663.162-1.152.592-1.152,1.082S7.4,7.827,8.064,7.989Z" transform="translate(0.15 0.256)" :fill="fillColor()" stroke="#fff" stroke-width="0.3"/>
  </svg>
</template>
<script setup lang="ts">
  const props = defineProps<{
    focus?: boolean;
  }>();

  const fillColor = () => props.focus ? '#FFFFFF' : '#7A7786';
</script>
