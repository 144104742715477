
import { createI18n } from 'vue-i18n';

import ptBR from '../locales/pt-BR.json';
import numberFormats from '../locales/numberFormats.json';

type MessageSchema = typeof ptBR;

type NumberSchema = typeof numberFormats;

// TODO: Remover do i18n as tags html para evitar problemas de ataques XSS
// https://vue-i18n.intlify.dev/guide/advanced/component.html#basic-usage

// TODO: Otimizar build do i18n com @intlify/unplugin-vue-i18n para remover alerta no console
// https://vue-i18n.intlify.dev/guide/advanced/optimization.html

export const i18n = createI18n<[MessageSchema, NumberSchema], 'pt-BR'>({
  locale: 'pt-BR',
  legacy: false,
  messages: {
    'pt-BR': ptBR,
  },
  numberFormats: numberFormats,
});

export const i18nGlobalInstance = i18n.global;
