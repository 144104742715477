<template>
<svg xmlns="http://www.w3.org/2000/svg" width="12.001" height="16" viewBox="0 0 12.001 16">
  <path id="ic_card" d="M3-13612a3,3,0,0,1-3-3v-10a3,3,0,0,1,3-3H9a3,3,0,0,1,3,3v10a3,3,0,0,1-3,3Zm-2-13v10a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2v-10a2,2,0,0,0-2-2H3A2,2,0,0,0,1-13625Zm6.2,2a.2.2,0,0,1-.2-.2v-1.6a.2.2,0,0,1,.2-.2h.6a.2.2,0,0,1,.2.2v1.6a.2.2,0,0,1-.2.2Z" transform="translate(0 13628.001)" :fill="fillColor()"/>
</svg>
</template>
<script setup lang="ts">
  const props = defineProps<{
    focus?: boolean;
    fillColor?: string;
  }>();

  const fillColor = () => props.focus ? '#FFFFFF' : props.fillColor ? props.fillColor : '#7A7786';
</script>
