<template>
  <InformationModal
      v-if="open"
      :with-pre="true"
      :title="t('components.spreadsheet.errors.title')"
      :subtitle="formattedErrors"
      @close="clearErrors"
    />
</template>

<script setup lang="ts">
  import { watch, ref, computed } from 'vue';
  import InformationModal from './form/InformationModal.vue';
  import { useI18n } from '@/composables/useI18n';
  import { useSpreadsheetErrorsStore } from '@/stores/errors';
  import { storeToRefs } from 'pinia';
  import router from '@/router';

  const open = ref(false);

  const { t } = useI18n();
  const store = useSpreadsheetErrorsStore();
  const { clearErrors } = store;

  const { hasErrors, errors } = storeToRefs(store);

  watch(hasErrors, () => {
    open.value = hasErrors.value;
  });

  const formattedErrors = computed(() => {
    let path: string;
    if(router.currentRoute.value.path.includes('carga')) {
      path = `${t('components.spreadsheet.errors.charges.subtitle')}\n\n`;
    } else {
      path = `${t('components.spreadsheet.errors.collaborators.subtitle')}\n\n`;
    }
    errors.value.forEach(error => {
      path += `Linha: ${error.line}: ${error.message}`;
      path += '\n';
    });

    return path;
  });
</script>