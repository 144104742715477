import type { IHeadOffice } from '@/interfaces/business';

import BusinessResources from '@/api/resources/business';

import { defineStore } from 'pinia';
import { useLoaderStore } from './loader';

export const useBusinessStore = defineStore({
  id: 'business',
  persist: true,
  state: ():{ headOffice: IHeadOffice | null} => ({
    headOffice: null,
  }),
  getters: {
    hasBusiness: (state) => !!state.headOffice,
    getHeadOffice: (state) => state.headOffice,
    getBusinessBalance: (state) => state.headOffice?.balance,
    getBusinessSecondWayFee: (state) => state.headOffice?.second_way_fee,
    getBusinessStatus: (state) => state.headOffice?.status,
  },
  actions: {
    fetchBusiness(callback?: (headOffice: IHeadOffice) => void) {
      const loaderStore = useLoaderStore();
      loaderStore.startLoading(true);
      BusinessResources.head_office()
        .then((headOffice) => {
          this.$patch({ headOffice });
          if (callback) callback(headOffice);
        });
    },
    resetBusiness() {
      this.$reset();
    },
  },
});