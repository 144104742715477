<template>
  <div @mouseenter="open = true" @mouseleave="open = false" :class="[open ? 'px-8 w-72' : 'w-24 px-2', 'border-solid border-r-2 duration-300 transition-all border-border-gray flex flex-col py-10 justify-between relative']">
    <div @click="toggle" :class="[open ? 'rotate-0' : 'rotate-180', 'cursor-pointer absolute duration-300 transition-all -right-4 flex bg-white border border-solid border-border-gray p-3 rounded-full z-10 items-center justify-center']">
      <MenuArrowBack />
    </div>
    <RouterLink to="/dashboard" class="relative">
      <img src="@/assets/images/ecx_logo.webp" :class="[open ? 'scale-100': 'scale-0 hidden','max-w-[110px] mx-auto w-full duration-300']" />
      <img src="@/assets/images/small_logo.webp" :class="[open ? 'scale-0 hidden': 'scale-100','w-full max-w-[40px] m-auto duration-300']" alt="ecxpay_logo">
    </RouterLink>
    <nav class="flex flex-col items-center float-left justify-center gap-y-2 w-full">
      <JeraNavButton
        :open="open"
        to="/tela-inicial"
        :text="t('button.dashboard')"
        v-slot="{ isActive }"
      >
        <DashboardIcon class="w-5" :focus="isActive" />
      </JeraNavButton>
      <JeraNavButton
        :open="open"
        to="/grupos"
        :text="t('button.groups')"
        v-slot="{ isActive }"
      >
        <GroupsIcon class="w-5" :focus="isActive"/>
      </JeraNavButton>
      <JeraNavButton
        :open="open"
        to="/colaboradores"
        :text="t('button.collaborators')"
        v-slot="{ isActive }"
      >
        <CollaboratorsIcon class="w-5" :focus="isActive"/>
      </JeraNavButton>
      <JeraNavButton
        :open="open"
        to="/carga"
        :text="t('button.charge')"
        v-slot="{ isActive }"
      >
        <ChargeIcon class="w-5" :focus="isActive"/>
      </JeraNavButton>
      <JeraNavButton
        :open="open"
        to="/relatorios"
        :text="t('button.reports')"
        v-slot="{ isActive }"
      >
        <ReportIcon class="w-5" :focus="isActive"/>
      </JeraNavButton>
      <JeraNavButton
        :open="open"
        to="/solicitar-cartao"
        :text="t('button.card_solicitation')"
        v-slot="{ isActive }"
      >
        <CardIcon class="w-5" :focus="isActive"/>
      </JeraNavButton>
      <JeraNavButton
        :open="open"
        to="/adiantamento-salarial"
        :text="t('button.wage_advance')"
        v-slot="{ isActive }"
      >
        <AdvanceIcon class="w-5" :focus="isActive"/>
      </JeraNavButton>
      <!-- <JeraNavButton
        :open="open"
        to="/transferencia"
        :text="t('button.transfer')"
        v-slot="{ isActive }"
      >
        <TransferIcon class="w-5" :focus="isActive"/>
      </JeraNavButton> -->
    </nav>
    <div class="flex flex-col gap-y-2">
      <div class="text-center text-xs text-primary mb-2 float-left">
        <a :href="termsOfUseLink" target="_blank" class="line-clamp-1">
          {{ t('button.terms_of_use') }}
        </a>
      </div>
      <RouterLink to="/" class="cursor-pointer flex justify-center" @click=doLogout>
        <LogoutIcon />
      </RouterLink>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { useI18n } from '@/composables/useI18n';
  import { useUserStore } from '@/stores/user';

  import JeraNavButton from '@/components/navigation/JeraNavButton.vue';
  import DashboardIcon from '@/assets/icons/dashboardIcon.vue';
  import CardIcon from '@/assets/icons/cardIcon.vue';
  import ChargeIcon from '@/assets/icons/chargeIcon.vue';
  import ReportIcon from '@/assets/icons/ReportIcon.vue';
  import CollaboratorsIcon from '@/assets/icons/collaboratorsIcon.vue';
  import GroupsIcon from '@/assets/icons/groupsIcon.vue';
  import LogoutIcon from '@/assets/icons/logoutIcon.vue';
  import AdvanceIcon from '@/assets/icons/AdvanceIcon.vue';
  import MenuArrowBack from '../icons/MenuArrowBack.vue';
  import { useSidebarStore } from '@/stores/sidebar';
  import { storeToRefs } from 'pinia';

  const sideStore = useSidebarStore();
  const { open } = storeToRefs(sideStore);
  const { toggle } = sideStore;

  const userStore = useUserStore();
  const { t } = useI18n();

  const doLogout = async () => {
    userStore.logout();
  };

  const termsOfUseLink = import.meta.env.VITE_TERM_OF_USE_URL;
</script>
