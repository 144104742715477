// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBBg6nbGMqH5L6v2TeknknJTEgWYBu9acc',
  authDomain: 'ecx-flutter.firebaseapp.com',
  projectId: 'ecx-flutter',
  storageBucket: 'ecx-flutter.appspot.com',
  messagingSenderId: '615870219916',
  appId: '1:615870219916:web:4dacb37493d25276909da9',
  measurementId: 'G-PCHQH37EFY',
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
