<template>
  <div v-if="isLoadingGlobal" class="fixed top-0 left-0 h-screen w-screen z-50 flex justify-center items-center bg-black bg-opacity-50">
    <div
      class="animate-spin rounded-full h-32 w-32 border-solid border-t-2 border-b-2 border-white"
    ></div>
  </div>
</template>
<script setup lang="ts">
  import { useLoaderStore } from '@/stores/loader';
  import { storeToRefs } from 'pinia';

  const { isLoadingGlobal } = storeToRefs(useLoaderStore());
</script>
