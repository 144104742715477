<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13.333" height="16" viewBox="0 0 13.333 16">
    <path id="ic_name" d="M109.327,70a.661.661,0,0,0-.189-.471L103.8,64.2a.66.66,0,0,0-.471-.19V64h-4A3.337,3.337,0,0,0,96,67.333v9.333A3.337,3.337,0,0,0,99.333,80H106a3.337,3.337,0,0,0,3.333-3.333V70ZM104,66.276l3.057,3.057H106a2,2,0,0,1-2-2Zm2,12.391H99.333a2,2,0,0,1-2-2V67.333a2,2,0,0,1,2-2h3.333v2A3.337,3.337,0,0,0,106,70.667h2v6A2,2,0,0,1,106,78.667Zm0-2.417a.666.666,0,0,1-.667.667H100a.667.667,0,0,1,0-1.333h5.333A.666.666,0,0,1,106,76.25Zm-6.667-2.667a.666.666,0,0,1,.667-.667h1.333a.667.667,0,1,1,0,1.333H100A.666.666,0,0,1,99.333,73.583Z" transform="translate(-96 -64)" :fill="fillColor()"/>
  </svg>
</template>
<script setup lang="ts">
  const props = defineProps<{
    focus?: boolean;
    color?: string;
  }>();

  const fillColor = () => props.focus ? '#FFFFFF' : '#7A7786';
</script>
