import { defineStore } from 'pinia';

export const useSidebarStore = defineStore({
  id: 'sidebarStore',
  persist: true,
  state: () => ({
    open: false,
  }),
  getters: {
    getMessages: (state) => state.open,
  },
  actions: {
    toggle() {
      this.open = !this.open;
    },
  },
});
