const Masks = {
  phone: (value: string) => value.replace(/(\d{2})?(\d{2})?(\d{4,5})?(\d{4})/g, '+$1 ($2) $3-$4'),
  cpf: (value: string) => value.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/g, '$1.$2.$3-$4'),
  cnpj: (value: string) => value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'),
  removeSlashFromDate: (value: string) => value.replace(/(\d+[/])(\d+[/])/, '$2$1'),
  formatDate: (value: string) => value.replace(/(\d*)(-)(\d*)(-)(\d*)/gi, '$5/$3/$1'),
  cep: (value: string) => value.replace(/(\d{5})(\d{3})/, '$1-$2'),
};

export default Masks;
