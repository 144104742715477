import { defineStore } from 'pinia';
import { useLoaderStore } from '@/stores/loader';
import { BannerResource } from '@/api/resources/banner';
import { type IDynamicBanner } from '@/interfaces/banner';
import { startOfDay } from 'date-fns';

interface IPopupStore {
  open: boolean;
  popupBanners: IDynamicBanner[];
  currentBanner: IDynamicBanner | null;
  reseted: boolean;
}

export const useBannerStore = defineStore({
  id: 'bannerStore',
  persist: true,
  state: (): IPopupStore => ({
    open: false,
    reseted: false,
    popupBanners: [],
    currentBanner: null,
  }),
  getters: {
    getBanners: (state) => state.popupBanners,
    getCurrentBanner: (state) => state.currentBanner,
    isModalOpen: (state) => state.open && state.currentBanner && !(state.currentBanner.alreadySeen),
    alreadySeenToday: (state) => {
      if(!(state.currentBanner?.seenDate)) return false;

      return new Date(state.currentBanner.seenDate) > startOfDay(new Date);
    },
  },
  actions: {
    reset() {
      this.open = false;
      this.popupBanners = [];
      this.currentBanner = null;
      this.reseted = true;
    },
    fetchPopupBanner() {
      const { startLoading } = useLoaderStore();
      startLoading(true);
      BannerResource.popup().then((response) => {
        const oldBanners = this.popupBanners;

        this.popupBanners = response.map((newBanner) => {
          const index = oldBanners.findIndex(oldBanner => oldBanner.id === newBanner.id);

          if(index != -1) {
            const oldBannerI = oldBanners[index];
            return { ...newBanner, alreadySeen: oldBannerI.alreadySeen, seenDate: oldBannerI.seenDate };
          }

          return { ...newBanner, alreadySeen: false, seenDate: undefined };
        });
      });
    },
    verifyBannerRoute(route: string) {
      if(!this.reseted) {
        this.reset();
      }

      const banner = this.popupBanners.filter((banner) => {
        return banner.route === route && !banner.alreadySeen;
      })[0];

      if(banner) {
        this.open = true;
        this.currentBanner = { ...banner, seenDate: banner.seenDate, alreadySeen: banner.alreadySeen };
      }
    },
    setAlreadySeen(value: boolean) {
      if (this.currentBanner) this.currentBanner.alreadySeen = value;

      const index = this.popupBanners.findIndex((banner) => banner.id === this.currentBanner?.id);
      if(index != -1) this.popupBanners[index].alreadySeen = value;
    },
    setSeenDate() {
      if (this.currentBanner) this.currentBanner.seenDate = new Date();

      const index = this.popupBanners.findIndex((banner) => banner.id === this.currentBanner?.id);
      if(index != -1) this.popupBanners[index].seenDate = new Date();
    },
    closeModal() {
      this.open = false;
      this.setSeenDate();
    },
  },

});