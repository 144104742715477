<template>
  <Teleport to="body">
    <div
      class="fixed top-0 left-0 h-screen w-screen z-40 flex justify-center items-center bg-default-black bg-opacity-25"
    >
      <div
        class="max-w-4xl bg-white opacity-100 py-16 px-24 rounded-lg text-center flex flex-col gap-y-10"
      >
        <div class="mx-auto border-solid border-2 border-primary bg-primary w-16 h-16 rounded-full text-primary font-bold flex items-center justify-center shadow-xl text-lg">
          <BorderCheckIcon class="w-8 h-8" />
        </div>

        <h1 class="text-4xl font-semibold text-default-black">
          <p v-html="title" />
        </h1>
        <span :class="withWitheSpace">
          <p v-html="subtitle" />
        </span>
        <div class="justify-center">
          <JeraButton
            name="close-button"
            :text="t('button.close')"
            class="text-white bg-primary outline max-w-xs outline-1 outline-border-gray"
            @click="close"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
  import JeraButton from './JeraButton.vue';
  import { useI18n } from '@/composables/useI18n';
  import router from '@/router';
  import { computed } from 'vue';
  import BorderCheckIcon from '@/assets/icons/BorderCheckIcon.vue';

  const { t } = useI18n();

  const props = defineProps<{
    title: string,
    subtitle: string,
    to?: string | undefined,
    withPre?: boolean,
    hasSuccessIcon?: boolean,
  }>();

  const emit = defineEmits<{
    (e: 'close'): void
  }>();

  const close = () => props.to ? router.push(props.to) : emit('close');

  const withWitheSpace = computed(() => {
    if(props.withPre) {
      return 'text-base whitespace-pre-wrap text-left text-default-black outline outline-1 outline-border-gray rounded-lg p-8 overflow-y-auto max-h-80';
    }

    return 'text-base text-left text-subtitle-gray outline outline-1 outline-border-gray rounded-lg p-8 overflow-y-auto max-h-80';
  });

</script>