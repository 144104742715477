import { defineStore } from 'pinia';

interface IToolbarStore {
  backPage?: CallableFunction;
}

export const useToolbarStore = defineStore({
  id: 'toolbar',
  state: (): IToolbarStore => ({
    backPage: undefined,
  }),
  actions: {
    setBackPage(callback: CallableFunction) {
      this.backPage = callback;
    },
    resetJeraToolbar() {
      this.$reset();
    },
  },
});
