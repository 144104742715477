<template>
  <fieldset
    class="flex gap-x-2 items-center rounded-lg duration-150 cursor-pointer"
    :class="fieldsetClassObject"
    @click="handleChange(id)"
  >
    <label
      :for="inputProps.id"
      class="select-none cursor-pointer rounded-lg flex outline outline-2 outline-input-outline-gray justify-center items-center h-4 w-4"
      @click.stop
    >
      <CheckIcon v-show="checked" class="w-3 h-3 items-center" />
    </label>
    <input
      type="checkbox"
      class="hidden"
      :id="inputProps.id"
      :name="inputProps.name"
      :value="id"
      :checked="checked"
    />

    <div class="flex flex-col gap-1">
      <p class="text-white">{{ name }}</p>
      <p class="text-subtitle-gray break-words" v-if="description">{{ description }}</p>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
  import CheckIcon from '@/assets/icons/CheckIcon.vue';

  import { useField } from 'vee-validate';
  import { computed, onMounted } from 'vue';

  interface IInputProps {
    id: string;
    name: string;
  }

  const props = defineProps<{
    id: number;
    name: string;
    description?: string;
    fieldChecked: boolean;
    inputProps: IInputProps;
  }>();

  const { checked, handleChange } = useField(props.inputProps.name, undefined, {
    type: 'checkbox',
    checkedValue: props.id,
  });

  const fieldsetClassObject = computed(() => {
    return [checked?.value ? 'border-primary' : 'border-border-gray'];
  });

  onMounted(() => {
    if (props.fieldChecked && !checked?.value) {
      handleChange(props.id);
    }
  });
</script>
