<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path id="ic_carga" d="M.8-2206a1.8,1.8,0,0,1-1.8-1.8v-8.4a1.8,1.8,0,0,1,1.554-1.781l-.173-.473a1.171,1.171,0,0,1,.038-.9,1.17,1.17,0,0,1,.665-.611l5.4-1.965a1.165,1.165,0,0,1,.4-.072,1.171,1.171,0,0,1,.5.112,1.171,1.171,0,0,1,.61.669l.361,1h2.9a1.181,1.181,0,0,1,1.179,1.18v1.051a1.79,1.79,0,0,1,1.636,1.79v1.781a1.185,1.185,0,0,1,.926,1.152v2.47a1.186,1.186,0,0,1-.926,1.152v1.843a1.8,1.8,0,0,1-1.8,1.8Zm-.673-10.2v8.4a.674.674,0,0,0,.673.674H12.278a.674.674,0,0,0,.673-.674v-1.815h-1.6a2.417,2.417,0,0,1-2.413-2.415,2.416,2.416,0,0,1,2.413-2.414h1.6v-1.754a.675.675,0,0,0-.673-.674H.8A.675.675,0,0,0,.123-2216.2Zm9.939,4.168a1.292,1.292,0,0,0,1.29,1.291h2.469a.056.056,0,0,0,.056-.055v-2.47a.056.056,0,0,0-.056-.056H11.352A1.292,1.292,0,0,0,10.062-2212.028Zm-3.024-7.009v1.044h4.278v-1.044a.056.056,0,0,0-.056-.056H7.093A.056.056,0,0,0,7.037-2219.037Zm-.171-1.835-5.4,1.965a.051.051,0,0,0-.032.028.059.059,0,0,0,0,.044l.309.842H5.913v-1.044a1.182,1.182,0,0,1,1.179-1.18h.068l-.224-.621a.055.055,0,0,0-.029-.032.07.07,0,0,0-.025,0A.046.046,0,0,0,6.867-2220.872Zm3.924,8.844a.563.563,0,0,1,.562-.562.562.562,0,0,1,.562.562.562.562,0,0,1-.562.563A.563.563,0,0,1,10.79-2212.028Z" transform="translate(1 2222)" :fill="fillColor()"/>
  </svg>
</template>
<script setup lang="ts">
  const props = defineProps<{
    focus?: boolean;
    fillColor?: string;
  }>();

  const fillColor = () => props.focus ? '#FFFFFF' : props.fillColor ? props.fillColor : '#7A7786';
</script>
