<template>
  <a
    v-if="disabled"
    href="javascript:void(0)"
    :class="[defaultStyle, disableClass]"
    @click="clickInDisableButton"
  >
    <span>
      {{ text }}
    </span>
  </a>
  <RouterLink
    v-else
    :to="{ path: to, query: { ...query } }"
    v-slot="{ isActive, href, navigate }"
    custom
  >
    <a
      :href="href"
      :class="[defaultStyle, buttonStyle(isActive)]"
      @click="navigate"
    >
      <div class="float-left">
        <slot :isActive="isActive"></slot>
      </div>
      <Transition name="slide-fade">
        <span v-if="open" class="line-clamp-1 h-fit">
          {{ text }}
        </span>
      </Transition>
    </a>
  </RouterLink>
</template>
<script setup lang="ts">
  import { RouterLink } from 'vue-router';
  import { useToasterStore } from '@/stores/toaster';
  import { computed } from 'vue';

  interface Props {
    text?: string;
    to: string;
    query?: object;
    disabled?: boolean;
    activeClass?: string;
    inactiveClass?: string;
    disableClass?: string;
    disableMessage?: string;
    open?: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    activeClass: 'text-white bg-primary',
    inactiveClass: 'bg-white text-subtitle-gray disabled hover:bg-border-gray',
    disableClass: 'opacity-10 text-white text-center justify-center bg-primary cursor-not-allowed',
  });

  const toasterStore = useToasterStore();

  const buttonStyle = (isActive: boolean) => {
    if (isActive) {
      return props.activeClass;
    }

    return props.inactiveClass;
  };

  const defaultStyle = computed(() => {
    return [props.open ? 'w-full justify-start text-sm' : 'w-14 justify-center', 'flex py-4 px-6 gap-x-4 rounded-lg text-base font-medium items-center duration-300 transition-all'];
  });

  const clickInDisableButton = () => {
    if (props.disabled && props.disableMessage) {
      toasterStore.addMessage({ text: props.disableMessage });
    }
  };
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>