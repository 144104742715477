/* eslint-disable @typescript-eslint/no-explicit-any */
import { BlipChat } from 'blip-chat-widget';

const BLIP_CHAT_API_KEY = import.meta.env.VITE_BLIP_CHAT_API_KEY as string;

export const blipChat = {
  install(app: never, { router }: { router: any }) {
    let blipChatInstance: BlipChat | null = null;

    const initializeBlipChat = () => {
      if (!blipChatInstance) {
        blipChatInstance = new BlipChat()
          .withAppKey(BLIP_CHAT_API_KEY)
          .withButton({ color: '#000ec6' })
          .build();
      }
    };

    const toggleBlipChatVisibility = (shouldShow: boolean) => {
      const blipChatButton = document.getElementById('blip-chat-container');
      if (blipChatButton) {
        blipChatButton.classList.add(shouldShow ? 'block' : 'hidden');
      }
    };

    router.afterEach((to: any) => {
      const noBlipChatRoutes = ['/swap-virtual-card'];
      const shouldShowChat = !noBlipChatRoutes.includes(to.path);

      toggleBlipChatVisibility(shouldShowChat);
    });

    initializeBlipChat(); // Inicializar o chat uma única vez
  },
};